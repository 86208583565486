import { LeaveApplicationsByFilters_leaveApplicationsByFilters_leaves } from '__generated__/LeaveApplicationsByFilters'
import {
  LeaveApplicationStatusEnum,
  LeaveTypeEnum,
  WorkerDepartmentEnum
} from '__generated__/globalTypes'
import { WorkerDepartmentMapping } from 'components/cleaner/CleanerValues'
import { Worker360 } from 'ts/types/Cleaner'
import { setDateTime } from 'utils/date'
import { getCapitalCase, workerName } from 'utils/helpers'
import * as Yup from 'yup'

import { FormFilterState } from './leave.slice'
import { worker_worker_workerLeaveAccount as WorkerAccount } from '__generated__/worker'
import { CleanerLeaveDetails } from 'components/cleaner/360/CleanerPage.screen'

export type Leave = {
  id: string
  workerId: string
  userName: string
  department: WorkerDepartmentEnum
  type: string
  leaveType: LeaveTypeEnum
  leaveStartDateTime: string
  leaveEndDateTime: string
  leaveHours: number
  leaveStatus: LeaveApplicationStatusEnum
  totalAffectedVisits: number | null
  workerLeaveAccount: RemainingCreditLeave
  manager: string
  reason: string
}

export const LeaveTypeMapping: {
  [key in LeaveTypeEnum]: string
} = {
  [LeaveTypeEnum.SL]: 'Medical Leave',
  [LeaveTypeEnum.AL]: 'Annual Leave',
  [LeaveTypeEnum.NPL]: 'Non Paid Leave',
  [LeaveTypeEnum.CL]: 'Covid Leave',
  [LeaveTypeEnum.HL]: 'Hospital Leave'
}

export type RemainingCreditLeave = {
  annualLeaveBalance: number
  hospitalLeaveBalance: number
  sickLeaveBalance: number
}

export const getLeaveCreditByLeaveType = (
  leaveAccount: RemainingCreditLeave,
  leaveType: LeaveTypeEnum
): string => {
  switch (leaveType) {
    case LeaveTypeEnum.AL:
      return `${Math.floor(leaveAccount.annualLeaveBalance)}`
    case LeaveTypeEnum.SL:
      return `${Math.floor(leaveAccount.sickLeaveBalance)}`
    case LeaveTypeEnum.CL:
      return `${Math.floor(leaveAccount.sickLeaveBalance)}`
    case LeaveTypeEnum.NPL:
      return '-'
    case LeaveTypeEnum.HL:
      return `${Math.floor(leaveAccount.hospitalLeaveBalance)}`
    default:
      console.log('Unhandled Leave Type', leaveType)
      return leaveType
  }
}

export const leaveFilterLabel: { [key in keyof FormFilterState]: string } = {
  AIRCON: 'Aircon',
  HOME_CLEANING: 'Home Cleaning',
  OFFICE_CLEANING: 'Office Cleaning',
  AL: 'Annual Leave',
  SL: 'Sick Leave',
  NPL: 'Non Paid Leave',
  HL: 'Hospital Leave',
  Bangladeshi: 'Bangladeshi',
  Burmese: 'Burmese',
  Cambodian: 'Cambodian',
  Indonesian: 'Indonesian',
  Singaporean: 'Singaporean',
  Chinese: 'Chinese',
  Malaysian: 'Malaysian',
  SriLankan: 'Srilankan',
  Thai: 'Thai',
  Indian: 'Indian'
}

export const transformLeaveAplication = (
  leave: LeaveApplicationsByFilters_leaveApplicationsByFilters_leaves
): Leave => {
  return {
    id: leave.id,
    workerId: leave.worker.id,
    userName: workerName(leave.worker),
    department: leave.worker.department,
    type: getCapitalCase(leave.worker.workerType),
    leaveType: leave.leaveType,
    leaveStartDateTime: `${leave.startDate}`,
    leaveEndDateTime: `${leave.endDate}`,
    leaveStatus: leave.status,
    totalAffectedVisits: leave.totalAffectedVisits,
    workerLeaveAccount: leave.worker.workerLeaveAccount as RemainingCreditLeave,
    manager: leave.worker.manager?.name ?? '',
    leaveHours: leave.hoursApplied ?? 0,
    reason: leave.reason ?? ''
  }
}

export const getLeaveApplicationsValues = (
  leaveApplicationsData: LeaveApplicationsByFilters_leaveApplicationsByFilters_leaves[]
): Leave[] => {
  return leaveApplicationsData.map((leave) => {
    return transformLeaveAplication(leave)
  })
}

export const FormSchema = Yup.object().shape({
  // cleanerId: Yup.string().required('Cleaner is a required field'),
  hoursApplied: Yup.number()
    .typeError('Leave hours must be a number')
    .required('Leave hours applied is a required field')
    .moreThan(3, 'Must be greater than or equal 4'),
  leaveType: Yup.string().required('Leave type is a required field'),
  startDate: Yup.string().required('Start date is a required field'),
  endDate: Yup.string()
    .test(
      'is-greater',
      'Must be greater than Start Date',
      function (endDate: string | undefined): boolean {
        const { startDate, endTime, startTime } = this.parent
        return (
          !!endDate &&
          setDateTime(endDate, endTime) >= setDateTime(startDate, startTime)
        )
      }
    )
    .nullable(),
  startTime: Yup.string().nullable(),
  endTime: Yup.string().nullable(),
  leaveCredit: Yup.string().nullable(),
  department: Yup.string().nullable(),
  reason: Yup.string()
})

const DEFAULT_START_TIME_HOURS = 6
const DEFAULT_END_TIME_HOURS = 22

export const getStartTime = () => {
  const date = new Date()
  date.setHours(DEFAULT_START_TIME_HOURS)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const getEndTime = () => {
  const date = new Date()
  date.setHours(DEFAULT_END_TIME_HOURS)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export type FormValues = {
  cleanerId: string
  workerName?: string
  department: string
  hoursApplied: string
  leaveType: LeaveTypeEnum
  startDate: Date
  startTime: Date
  endDate: Date
  endTime: Date
  leaveCredit: string
  reason: string
}

export const initialValues = (
  leave: Leave | null,
  selectedWorker?: Worker360,
  workerLeaveCredit?: RemainingCreditLeave
): FormValues => {
  const startDate = leave && new Date(leave.leaveStartDateTime)
  const endDate = leave && new Date(leave.leaveEndDateTime)
  return {
    cleanerId: selectedWorker?.id ?? leave?.workerId ?? '',
    department: selectedWorker
      ? WorkerDepartmentMapping[selectedWorker.department]
      : leave
      ? WorkerDepartmentMapping[leave.department]
      : '',
    hoursApplied: leave ? leave.leaveHours.toString() : '',
    leaveType: leave ? leave.leaveType : LeaveTypeEnum.AL,
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
    startTime: startDate ?? getStartTime(),
    endTime: endDate ?? getEndTime(),
    leaveCredit:
      leave && workerLeaveCredit
        ? getLeaveCreditByLeaveType(workerLeaveCredit, leave.leaveType)
        : '',
    reason: leave ? leave.reason : ''
  }
}

export const entitleCreditLeaveDetail: CleanerLeaveDetails = {
  annualLeaveAccrued: 0,
  annualLeaveBalance: 0,
  annualLeaveTaken: 0,
  hospitalLeaveTaken: 0,
  hospitalLeaveAccrued: 0,
  hospitalLeaveBalance: 0,
  noPayLeaveTaken: 0,
  sickLeaveAccrued: 0,
  sickLeaveBalance: 0,
  sickLeaveTaken: 0
}

export const getCleanerLeaveAccount = (leaveAccount: WorkerAccount | null | undefined): CleanerLeaveDetails => {
  if (!leaveAccount) {
    return entitleCreditLeaveDetail
  }

  return {
    annualLeaveAccrued: leaveAccount.annualLeaveAccrued,
    annualLeaveBalance: leaveAccount.annualLeaveBalance,
    annualLeaveTaken: leaveAccount.annualLeaveTaken,
    hospitalLeaveTaken: leaveAccount.hospitalLeaveTaken,
    hospitalLeaveAccrued: leaveAccount.hospitalLeaveAccrued,
    hospitalLeaveBalance: leaveAccount.hospitalLeaveBalance,
    noPayLeaveTaken: leaveAccount.noPayLeaveTaken,
    sickLeaveAccrued: leaveAccount.sickLeaveAccrued,
    sickLeaveBalance: leaveAccount.sickLeaveBalance,
    sickLeaveTaken: leaveAccount.sickLeaveTaken
  }
}

